<template>
  <div>
    <a-modal
      title="外链素材列表"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      :width="1200"
    >
      <div class="modalDiv">
        <!-- 左 -->
        <div style="border-right: 2px solid #e9e9e9">
          <div class="menu" id="menu">
            <a-menu mode="inline" @click="handleClick" v-model="selectId">
              <a-menu-item key="1"> 全部外链 </a-menu-item>
              <a-menu-item key="-1">默认分类</a-menu-item>
              <a-menu-item
                v-for="(item, index) in topData"
                :key="item.id"
                v-model="typeid"
              >
                {{ item.title }}
              </a-menu-item>
              <a-menu-item>
                <a-input
                  autocomplete="off"
                  id="addMenu"
                  placeholder="请输入"
                  v-show="addInput"
                  v-model="addTitle"
                  @pressEnter="addMenuTitle"
                ></a-input>
              </a-menu-item>
            </a-menu>
          </div>
          <div @click="addMenu" class="addMenu">
            <span>新增栏目</span>
          </div>
        </div>
        <!-- 右 -->
        <div>
          <div class="content-list">
            <!-- 顶部 -->
            <div class="content-list-top">
              <a-button @click.native="addLink" class="add" type="primary"
                >+添加</a-button
              >
              <div>
                <a-input-search
                  allowClear
                  style="width: 250px"
                  placeholder="请输入外链名称"
                  enter-button="搜索"
                  v-model="linkName"
                  @search="search"
                />
              </div>
            </div>
            <!-- 表格 -->

            <!-- <div class="add" v-show="visible2" @click="addLink">
                <p class="addp">+添加</p>
              </div> -->
            <!-- <a-button @click.native="addLink" class="add" type="primary"
                >+添加</a-button
              > -->
            <a-spin :spinning="spin">
              <div class="content-two-list-card">
                <!-- 表格 -->
                <a-table
                  :scroll="{ y: 350 }"
                  :row-selection="info === true ? rowSelection : rowSelections"
                  :columns="columns"
                  :rowKey="
                    (record, index) => {
                      return record.id;
                    }
                  "
                  :data-source="link"
                  :pagination="false"
                >
                  <!-- 图片 -->
                  <template slot="is_Img" slot-scope="text, record, index">
                    <!-- handleChange最后一个参数，是要修改的对象的key -->

                    <div
                      class="bgImg"
                      style="position: relative"
                      v-if="record.editable"
                    >
                      <div
                        style="
                          position: absolute;
                          width: 100%;
                          text-align: center;
                        "
                      >
                        <div
                          style="
                            font-size: 17px;
                            color: #929292;
                            padding: 10px 20px;
                          "
                          v-if="record.imgedit"
                        >
                          <span v-if="text === ''"> +上传 </span>
                        </div>
                      </div>
                      <input
                        :id="'materialLibrary' + record.id"
                        :ref="'materialLibrary' + record.id"
                        style="
                          width: 100%;
                          height: 100%;
                          z-index: 999999;
                          opacity: 0;
                          position: absolute;
                          top: 0;
                          left: 0;
                        "
                        accept="image/*"
                        type="file"
                        @change="materialLibrary(record.id, 'imageUrl')"
                      />

                      <div>
                        <img
                          v-if="text != ''"
                          alt=""
                          :src="text"
                          style="width: 100%; height: 45px"
                        />
                      </div>
                    </div>

                    <!--
          <a-input v-if="record.editable" style="margin: -5px 0" :value="text"
            @change="e => handleChange(e.target.value, record.id, 'imageUrl')" /> -->
                    <template v-else>
                      <img style="width: 100%; height: 50px" :src="text" />
                    </template>
                  </template>
                  <!-- 名称 -->
                  <template slot="linNo" slot-scope="text, record, index">
                    <a-input
                      v-if="record.editable"
                      style="margin: -5px 0"
                      :value="text"
                      @change="
                        (e) => handleChange(e.target.value, record.id, 'name')
                      "
                    />
                    <template v-else>
                      <diV
                        style="
                          width: 100%;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ text }}
                      </diV>
                    </template>
                  </template>
                  <!-- 链接 -->
                  <template slot="is_link" slot-scope="text, record, index">
                    <a-input
                      v-if="record.editable"
                      style="margin: -5px 0"
                      :value="text"
                      @change="
                        (e) => handleChange(e.target.value, record.id, 'link')
                      "
                    />
                    <template v-else>
                      <diV
                        style="
                          width: 100%;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        <a :href="text" target="_blank">{{ text }}</a>
                      </diV>
                    </template>
                  </template>
                  <!-- 分类 -->
                  <template slot="is_type" slot-scope="text, record, index">
                    <div
                      v-if="record.editable"
                      style="width: 97%; display: flex"
                    >
                      <a-select
                        :placeholder="record.columnName"
                        @change="(e) => handleSelectChange(e, record.id)"
                        style="width: 100px"
                      >
                        <a-select-option
                          v-for="(item, index) in topData"
                          :key="item.id"
                          :value="`${item.id}&&${item.title}`"
                        >
                          {{ item.title }}
                        </a-select-option>
                      </a-select>
                      <a-icon
                        type="reload"
                        @click="clearAdd"
                        style="
                          font-size: 17px;
                          color: #1890ff;
                          line-height: 35px;
                          margin-left: 10px;
                        "
                      />
                      <a-icon
                        type="plus-square"
                        @click="addAgain"
                        style="
                          font-size: 17px;
                          color: #1890ff;
                          line-height: 35px;
                          margin-left: 10px;
                        "
                      />
                    </div>
                    <span v-else>
                      {{ record.columnName }}
                    </span>
                    <!-- <a-input v-if="record.editable" style="margin: -5px 0" :value="text"
            @change="e => handleChange(e.target.value, record.id, 'columnId')" />
          <template v-else>
            {{ text }}
          </template> -->
                  </template>

                  <template slot="operation" slot-scope="text, record, index">
                    <div class="editable-row-operations">
                      <span v-if="record.editable">
                        <a
                          @click="() => save(record, record.id)"
                          style="margin-right: 5px; font-weight: 550"
                          >保存</a
                        >
                        &ensp;
                        <a-popconfirm
                          title="确认取消?"
                          @confirm="() => cancel(record.id)"
                        >
                          <a style="margin-right: 5px; font-weight: 550"
                            >取消</a
                          >
                        </a-popconfirm>
                      </span>
                      <span v-else>
                        <a
                          @click="() => edit(record.id)"
                          style="margin-right: 5px; font-weight: 550"
                          >编辑</a
                        >
                        <a
                          style="margin-right: 5px; font-weight: 550"
                          @click="moveLink(record)"
                        >
                          移动分类
                        </a>
                        <a-popconfirm
                          title="确定删除?"
                          ok-text="是"
                          cancel-text="否"
                          @confirm="del(record)"
                          @cancel="cancel1"
                        >
                          <a style="font-weight: 550">删除</a>
                        </a-popconfirm>
                      </span>
                    </div>
                  </template>
                </a-table>
              </div>
              <!-- 分页 -->
              <div
                style="
                  display: block;
                  width: 100%;
                  height: 50px;
                  text-align: center;

                  margin-top: 0px;
                "
              >
                <a-pagination
                  :current="curPage"
                  :total="total"
                  :pageSize="pageSize"
                  show-quick-jumper
                  @change="PageNOchange"
                  style="display: inline-block"
                />
              </div>
            </a-spin>
          </div>
        </div>
        <!-- 移动分组弹窗 -->
        <a-modal
          :visible="visible1"
          title="选择分类"
          @ok="moveOk"
          width="30%"
          @cancel="moveCancel"
          style="position: fixed; z-index: 999999"
        >
          <a-radio-group
            button-style="solid"
            :default-value="categoryId"
            :key="categoryId"
            style="padding: 10px 10px 30px 10px"
          >
            <a-radio-button
              :value="-1"
              :key="-1"
              @click="handle(-1)"
            >默认分类</a-radio-button>
            <a-radio-button
              :value="item.id"
              v-for="item in topData"
              :key="item.id"
              @click="handle(item.id)"
            >
              {{ item.title }}
            </a-radio-button>
          </a-radio-group>
        </a-modal>

        <!-- -----------------新增对话框 ----------------------------------->
        <a-modal
          title="新增"
          :visible="visible3"
          @ok="addOk"
          @cancel="addCancel"
        >
          <a-form-model
            :model="form"
            :rules="addRules"
            ref="ruleForm_add"
            style="display: flex"
          >
            <a-form-model-item
              label="分类标题"
              prop="name"
              style="display: flex"
            >
              <a-input
                placeholder="请输入"
                v-model="form.name"
                style="display: flex; margin-top: 5px; width: 220px"
              />
            </a-form-model-item>
          </a-form-model>
        </a-modal>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  getManagement,
  AddManagement,
  getLink,
  changeLink,
  delLink,
  InsertLink,
} from "../../service/columnmanageapi";
import { postImgList } from "@/service/document_api";
import { update, update_File } from "@/utils/update";
export default {
  name: "pictureDialog",
  data() {
    return {
      spin: false,
      visible1: false, //移动分组弹窗
      visible3: false, //新增类型弹窗
      topData: [],
      // selectedRows: [],
      selectedRowKeys: [],
      addTitle: "",
      addInput: false,
      picture: [],
      curPage: 1, //当前页
      total: 0, //总数
      pageSize: 5,
      iconList: [],
      link: [],
      typeid: "",
      imgName: "",
      editingKey: "",
      linkName: "",
      category: {},
      checkList: [],
      selectId: ["1"],
      visible2: true,
      categoryId: "",
      // visible3: false,
      form: {
        name: "",
        code: "1",
      },
      addform: {},
      // 表格数据缓存
      cacheData: [],
      // 添加的测试
      addtableCount: 0,
      columns: [
        {
          title: "图片",
          dataIndex: "imageUrl",
          width: "14%",
          key: "imageUrl",
          align: "center",
          scopedSlots: { customRender: "is_Img" },
        },
        {
          title: "名称",
          dataIndex: "name",
          width: "15%",
          key: "name",
          align: "center",
          scopedSlots: { customRender: "linNo" },
        },
        {
          title: "链接",
          dataIndex: "link",
          width: "17%",
          key: "link",
          align: "left",
          scopedSlots: { customRender: "is_link" },
        },
        {
          title: "创建时间",
          dataIndex: "createdTime",
          width: "14%",
          key: "createdTime",
          align: "center",
        },
        {
          title: "分类",
          dataIndex: "columnName",
          width: "20%",
          key: "columnName",
          ellipsis: true,
          align: "center",
          scopedSlots: { customRender: "is_type" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "18%",
          scopedSlots: { customRender: "operation" },
          key: "operation",
          align: "center",
        },
      ],
      addRules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (/^[^\s]+[\s]*.*$/i.test(value) == false) {
                callback(new Error("请输入正确的名称"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    // 接收父组件传递的参数
    visible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.load();
    this.getType();
  },
  methods: {
    // 类型
    load() {
      (this.spin = true),
        //  获取外链
        getLink({
          name: this.linkName,
          columnId: this.typeid,
          startTime: this.startTime,
          endTime: this.endTime,
          curPage: this.curPage,
          pageSize: this.pageSize,
        }).then((res) => {
          this.spin = false;
          // 如果原本有数据，则取出editable=true的数据
          if (this.link.filter((item) => item.editable === true).length > 0) {
            const editableData = this.link.filter(
              (item) => item.editable === true
            );
            const newData = [...editableData];
            this.link = [...newData, ...res.data];
            // 表格数据，备份
            this.cacheData = [...newData, ...res.data]; // res.data.map(item => ({ ...item }));
            this.total = res.count;
          } else {
            this.link = res.data;
            // 表格数据，备份
            this.cacheData = res.data.map((item) => ({ ...item }));
            this.total = res.count;
          }
        });
    },
    getType() {
      getManagement({
        type: "link",
        curPage: 1,
        pageSize: 9999,
      }).then((res) => {
        // console.log(res);
        this.topData = res.data;
      });
    },
    handleClick(e) {
      this.curPage = 1;
      this.clearData();
      if (e.key === "1") {
        this.typeid = "";
      } else {
        this.typeid = e.key;
      }
      this.load();
    },
    handleCancel() {
      let flag = false;
      this.addInput = false;
      this.$emit("closeMain", flag); //子组件向父组件传值(开关)
      this.selectId = ["1"];
      this.typeid = "";
      this.link = [];
      this.load();
      this.getType();
      this.clearData();
      document.getElementById("menu").scrollTop = 0;
    },
    handleOk() {
      let flag = false;
      this.$emit("closeMain", flag); //子组件向父组件传值（开关）
      // console.log("链接", this.checkList);
      this.$emit("checkList", this.checkList); //子组件向父组件传值(勾选的内容)
      this.checkList = [];
      this.clearData();
      this.selectId = ["1"];
      this.typeid = "";
      this.link = [];
      this.load();
      this.getType();
      document.getElementById("menu").scrollTop = 0;
    },
    // 新增栏目回调
    addMenuTitle() {
      var input = /^[\s]*$/;
      if (!input.test(this.addTitle)) {
        let data = {
          type: "link",
          title: this.addTitle,
        };
        AddManagement(data).then((res) => {
          //添加栏目回调
          if (res.code == 200) {
            this.$message.success("新增成功");
            this.addInput = false;
            this.getType();
            this.addTitle = "";
            this.$emit("addMenu", data); //子组件向父组件传值（新增的栏目）
            document.getElementById("menu").scrollTop = 0;
            // console.log("data", data);
          } else {
            this.$message.warning("新增失败");
            document.getElementById("menu").scrollTop = 0;
            this.getType();
            this.addInput = false;
          }
        });
      } else {
        this.$message.warning("请输入正确的类别");
      }
    },
    // 点击添加栏目
    addMenu() {
      this.addInput = true;
      // document.getElementById("menu").scrollHeight;
      this.$nextTick(function () {
        document.getElementById("addMenu").focus();
      });
    },
    cancelAddMenuTitle() {
      this.addInput = false;
      this.addTitle = "";
      document.getElementById("menu").scrollTop = 0;
    },
    // // 改变页码
    // PageNOchange(curPage) {
    //   this.curPage = curPage;
    //   this.load();
    // },
    // 搜索
    search() {
      this.curPage = 1;
      this.load();
    },

    // 打开移动分组
    moveLink(record) {
      this.visible1 = true;
      this.category.id = record.id;
      this.categoryId = record.columnId;
    },

    handle(id) {
      this.category.columnId = id;
    },
    moveOk() {
      changeLink({
        id: this.category.id,
        columnId: this.category.columnId,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success("移动成功");
          this.load();
          this.visible1 = false;
          this.categoryId = "";
        } else {
          this.$message.warning("移动失败");
          this.visible1 = false;
          this.categoryId = "";
        }
      });
    },
    moveCancel() {
      this.categoryId = "";
      this.visible1 = false;
      this.load();
    },
    del(record) {
      delLink(record.id).then((res) => {
        if (res.code === 0) {
          this.$message.success("删除成功");
          this.load();
        } else {
          this.$message.warning("删除失败");
          this.load();
        }
      });
    },
    cancel1() {
      this.$message.warning("取消删除");
      this.load();
    },
    // 改变页码
    PageNOchange(curPage) {
      // console.log(curPage);
      this.curPage = curPage;
      this.load();
    },
    // 表格编辑，变化时
    handleChange(value, id, column) {
      // console.log("表格变化", value, id, column);
      const newData = [...this.link];
      const target = newData.find((item) => id === item.id);
      if (target) {
        target[column] = value;
        // console.log(target[column]);
        this.link = newData;
      }
    },
    // 表格，编辑
    edit(id) {
      // console.log(id);
      const newData = [...this.link];
      const target = newData.find((item) => id === item.id);
      if (target) {
        target.editable = true;
        //标志图片可编辑，上传时，改为false
        target.imgedit = true;
        this.link = newData;
        // console.log(this.link);
      }
    },
    // 表格，保存
    save(record, id) {
      // console.log(record);
      let flat = false;
      // console.log(record);
      if (record.name == "") {
        flat = true;
        this.$message.warning("请输入名称");
      }

      if (record.imageUrl == "") {
        flat = true;
        this.$message.warning("请上传图片");
      }
      if (flat) {
        return;
      }
      const newData = [...this.link];
      const target = newData.find((item) => id === item.id);
      // 如果是我手动添加的则点击保存时，调用插入接口
      if (target && target.myedit) {
        let a = [];
        a.push({
          name: record.name,
          imageUrl: record.imageUrl,
          link: record.link,
          columnId: parseInt(record.columnId),
        });
        InsertLink(a).then((res) => {
          if (res.code == 0) {
            // 表格数据，
            const newData = [...this.link];
            // 表格的备份数据
            const newCacheData = [...this.cacheData];
            const target = newData.find((item) => id === item.id);
            const targetCache = newCacheData.find((item) => id === item.id);
            if (target && targetCache) {
              delete target.editable;
              this.link = newData;
              Object.assign(targetCache, target);
              this.cacheData = newCacheData;
              this.$message.success("保存成功");
            }
            // 刷新列表
            this.load();
          } else {
            // 表格数据，
            this.cancel(id);
            this.$message.warning("保存失败");
          }
        });
      } else {
        //否则调用修改接口
        changeLink({
          id: id,
          name: record.name,
          imageUrl: record.imageUrl,
          link: record.link,
          columnId: parseInt(record.columnId),
        }).then((res) => {
          if (res.code == 0) {
            // 表格数据，
            const newData = [...this.link];
            // 表格的备份数据
            const newCacheData = [...this.cacheData];
            const target = newData.find((item) => id === item.id);
            const targetCache = newCacheData.find((item) => id === item.id);
            if (target && targetCache) {
              delete target.editable;
              this.link = newData;
              Object.assign(targetCache, target);
              this.cacheData = newCacheData;
              this.$message.success("修改成功");
            }
            // 刷新列表
            this.load();
          } else {
            // 表格数据，
            this.cancel(id);
            this.$message.warning("修改失败");
          }
        });
      }
    },

    // 表格

    cancel(id) {
      const newData = [...this.link];
      const newCacheData = [...this.cacheData];
      const target = newData.find((item) => id === item.id);
      // this.editingKey = '';
      if (target) {
        // 还原改变的数据
        Object.assign(
          target,
          this.cacheData.find((item) => id === item.id)
        );
        // 如果是我手动添加的数据，取消时从列表删除
        if (target.myedit) {
          this.link = newData.filter((item) => {
            return item.id != id;
          });
          this.cacheData = newCacheData.filter((item) => {
            return item.id != id;
          });
          return;
        }
        delete target.editable;
        this.link = newData;
      }
    },

    // 上传封面图
    async materialLibrary(id, column) {
      // console.log(this.$refs[`materialLibrary${id}`].files);
      // return
      let inputDOM = this.$refs[`materialLibrary${id}`].files[0];
      const image = await update(inputDOM);
      if (image.code === 0) {
        // 修改表格中的imgurl,张灯珍
        const newData = [...this.link];
        const target = newData.find((item) => id === item.id);
        if (target) {
          target[column] = image.data.url;
          //标志图片可编辑，上传时，改为false
          target.imgedit = false;
          // console.log(target[column]);
          this.link = newData;
        }

        // this.$set(this.addForm, "imageUrl", image.data.url);
        // console.log("111", this.addForm);
        this.$message.success("上传成功");
      } else {
        this.$message.warning(image.message);
      }
      document.getElementById([`materialLibrary${id}`]).value = null;
    },

    submit() {
      this.add.push(this.addForm);
      InsertLink(this.add).then((res) => {
        if (res.code == 0) {
          this.load();
          this.$message.success("保存成功");
          this.editForm = {};
          this.visible1 = true;
          this.newAdd = [];
        } else {
          this.$message.warning("保存失败");
          this.addForm = {};
          this.load();
          this.visible = true;
        }
      });
    },
    cancelAdd() {
      this.addForm = {};
      this.load();
    },
    // 表格，重置
    // clearAdd(id) {
    //   const newData = [...this.link];
    //   const target = newData.find((item) => id === item.id);
    //   if (target) {
    //     target.editable = true;
    //     //标志图片可编辑，上传时，改为false
    //     target.imgedit = true;
    //     target.imageUrl = "";
    //     target.link = "";
    //     target.name = "";
    //     (target.columnId = -1), (target.columnName = "");
    //     this.link = newData;
    //     console.log(target);
    //   }
    // },
    // addAgain() {
    //   this.addLink();
    // },
    clearAdd() {
      this.getType();
    },
    addAgain() {
      // this.addLink();
      this.visible3 = true;
      this.form.name = "";
    },
    // 表格，下拉框
    handleSelectChange(value, id) {
      // console.log(
      //   value.split("&&"),
      //   value.split("&&")[0],
      //   value.split("&&")[1],
      //   id,
      //   "下拉框"
      // );
      const newData = [...this.link];
      const target = newData.find((item) => id === item.id);
      if (target) {
        target["columnId"] = value.split("&&")[0];
        target["columnName"] = value.split("&&")[1];
        // console.log(target["columnId"], target["columnName"]);
        this.link = newData;
      }
    },
    // 添加框
    addLink() {
      //表格数据
      let newData = [...this.link];
      //表格数据备份，用于取消编辑时还原数据
      let newCacheData = [...this.link];
      this.addtableCount++;
      newData.unshift({
        columnId: -1,
        columnName: "",
        createdById: "",
        createdByName: "",
        createdTime: "",
        id: this.getCurTime(),
        imageUrl: "",
        link: "",
        name: "",
        imgedit: true, //图片为可编辑状态
        editable: true,
        myedit: true, //用来移除，我的添加
      });
      newCacheData.push({
        columnId: -1,
        columnName: "",
        createdById: "",
        createdByName: "",
        createdTime: "",
        id: this.getCurTime(),
        imageUrl: "",
        link: "",
        name: "",
        imgedit: true, //图片为可编辑状态
        editable: true,
        myedit: true, //用来移除，我的添加
      });
      this.link = newData;
      this.cacheData = newCacheData;
      // console.log(this.link);
    },
    // 获取当前时间
    getCurTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = ("0" + (now.getMonth() + 1)).slice(-2);
      const day = ("0" + now.getDate()).slice(-2);
      const hours = ("0" + now.getHours()).slice(-2);
      const minutes = ("0" + now.getMinutes()).slice(-2);
      const seconds = ("0" + now.getSeconds()).slice(-2);
      const formattedTime = year + month + day + hours + minutes + seconds;
      return formattedTime;
    },
    clearData() {
      this.selectedRowKeys = []; // 调用这个方法就有效果了
    },
    // 新增确定
    addOk() {
      this.$refs.ruleForm_add.validate(async (valid) => {
        if (valid) {
          let form = {
            title: this.form.name,
            type: "link",
          };
          AddManagement(form).then((res) => {
            if (res.code == 200) {
              this.$message.success("新增成功");
              this.visible3 = false;
              this.form.name = "";
              this.$refs.ruleForm_add.resetFields();
              this.getType();
            } else {
              this.$message.warning("新增失败");

              this.$refs.ruleForm_add.resetFields();
            }
          });
        }
      });
    },
    addCancel() {
      this.visible3 = false;
      this.$refs.ruleForm_add.resetFields();
      this.form.name = "";
    },
  },

  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          this.checkList = selectedRows;
        },

        type: "radio",
      };
    },
    rowSelections() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          this.checkList = selectedRows;
        },

        type: "checkbox",
      };
    },
  },
};
</script>

<style scoped lang="scss">
.modalDiv {
  width: 100%;
  display: flex;
  height: 530px;
  margin-top: -24px;
  margin-left: -24px;
  margin-left: -24px;
  // margin-top: -24px;
  // margin-left: -24px;
  // border-right: 2px solid #467ac9;
  .menu {
    width: 150px;
    border: none;

    position: relative;
    height: 520px;
    // background-color: #1890ff;
    overflow-y: scroll;
    // border-right: 2px solid #467ac9;
  }
  .addMenu {
    position: absolute;
    bottom: 70px;
    left: 15px;
    display: inline-block;
    display: flex;
    width: 9%;
    height: 40px;
    font-size: 17px;
    border: 3px dashed #edeff2;
    cursor: pointer;
    span {
      margin: auto;
      color: #1890ff;
    }
  }
  .content {
    width: 100%;
    border-left: 1px solid #e7e7e7;
    .content-top {
      height: 200px;
      border-bottom: 1px solid #e7e7e7;
      display: flex;
      .top-icon {
        width: 10%;
        text-align: center;
        .icon {
          line-height: 200px;
          font-size: 40px;
          color: #929292;
          cursor: pointer;
        }
      }
      .imgList {
        width: 80%;
        display: flex;
        margin-top: 20px;
        .imgD {
          height: 160px;
          width: 150px;
          margin-right: 30px;
        }
      }
    }
    // .content-list {
    //   height: 300px;
    //   overflow-y: scroll;
    //   overflow-x: hidden;
    //   display: flex;

    //   .content-list-top {
    //     width: 100%;
    //     display: flex;
    //     justify-content: space-between;
    //     background-color: red;
    //     // margin: 20px 0 0 0;
    //     // background-color: #45a5e6;
    //   }
    //   .content-two-list-card {
    //     width: 100%;
    //     height: 425px;
    //     // margin-top: 20px;
    //     // margin-left: 10px;
    //   }
    // }
  }
}
.content-list {
  // height: 300px;
  // overflow-y: scroll;
  // overflow-x: hidden;
  // display: flex;

  .content-list-top {
    width: 100%;
    display: flex;
    justify-content: space-between;

    margin-left: 2%;
    margin-top: $LineSpacing;
    margin-bottom: $LineSpacing;
    // margin: 20px 0 0 0;
    // background-color: #45a5e6;
  }
  .content-two-list-card {
    width: 100%;
    height: 425px;
    margin-left: 2%;
    // margin-top: 20px;
    // margin-left: 10px;
  }
}
.item {
  position: relative;
  width: 23%;
  // height: 100px;
  aspect-ratio: 1/1;
  // float: left;
  display: inline-block;
  // background-color: #1890ff;
  border: 1.3px solid #edeff2;
  border-radius: 8px;
  margin-left: 14px;
  margin-top: 15px;
  // margin-bottom: 20px;
  .checkbox {
    position: absolute;
    top: 1.5%;
    left: 1.5%;
  }
  .yuan {
    position: absolute;
    top: 1.5%;
    right: 1.5%;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: white;
    z-index: 0;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  .box {
    position: absolute;
    top: 19%;
    right: 4%;
    width: 40%;
    height: 40%;
    background: #fff;
    z-index: 999;
    border-radius: 5px;
    span {
      display: inline-block;
      background: #fff;
      width: 100%;
      height: 33.4%;
      cursor: pointer;
    }
  }
}
// .add {
//   width: 98%;
//   height: 40px;
//   display: block;
//   margin: 2% 1%;
//   border-radius: 10px;
//   border: 2px dashed #c2cbcb;
//   cursor: pointer;
//   display: flex;
//   .addp {
//     display: block;
//     margin: auto;
//   }
// }
.add {
  // margin-top: $LineSpacing;
  // margin-bottom: $LineSpacing;
  // margin-right: 2%;
  // float: right;
  // display: block;
  // z-index: 99;
}
.bgImg {
  border: #dddddd 2px dashed;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  text-align: center;
}
.ant-radio-button-wrapper {
  border-radius: 5px;
  border: none;
  &::before {
    display: none; // 隐藏分界线
  }
}
::v-deep .ant-radio-button-wrapper::before {
  display: none;
}

::v-deep .ant-table-tbody > tr > td {
  padding: 7px;
}
::v-deep .ant-modal-footer {
  margin-top: -24px;
}
// ::v-deep .ant-modal-content .ant-modal-header {
//   border-bottom: none !important;
// }

// ::v-deep .ant-modal-content .ant-modal-footer {
//   border-top: none !important;
// }
</style>
