<template>
  <div class="ExhibitionHall">
    <div class="ExhibitionHall-title">
      <div class="title">直播间设置-云展厅</div>
      <div>开启状态：
        <a-switch
            style="margin-right: 10px"
            v-model="isShow"
            @change="switchChange(isShow)"
            checked-children="开"
            un-checked-children="关"
            default-checked />
      </div>
    </div>
    <div class="ExhibitionHall-content">
      <div style="display: flex;justify-content: space-between">
        <a-button type="primary" @click="addTable">+ 新增</a-button>
        <a-input-search
            v-model="titleVal"
            style="width: 400px;margin-bottom: 20px;"
            placeholder="请输入发言人姓名或内容关键字"
            enter-button="搜索"
            allow-clear
            @search="onSearch"
        />
      </div>
      <a-table
          :columns="columns"
          :data-source="data"
          :row-key="record => record.id"
          :pagination="false">
        <span slot="serialNumber" slot-scope="text, record, index">
          {{index+1}}
        </span>
        <span slot="imageUrl" slot-scope="text, record, index">
          <img :src="record.imageUrl" style="width: 120px;height: 80px;" alt="">
        </span>
        <span slot="name" slot-scope="text, record, index">

          <a v-if="record.link" @click="openLink(record.link)">{{ text?text:'未设置' }}</a>
          <span v-else>{{ text?text:'未设置' }}</span>
        </span>
        <template slot="action" slot-scope="text, record, index">
               <a @click="upOrDown('up',record.id)" v-show="index !== 0">上移</a>
               <a style="margin-left: 5px;margin-right: 5px" @click="upOrDown('down',record.id)" v-show="index !== data.length -1">下移</a>
               <a-dropdown :disabled="editingKey !== ''">
                 <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                   更多 <a-icon type="down" />
                 </a>
                 <a-menu slot="overlay">
                   <a-menu-item>
                      <a-popconfirm
                          title="是否确认删除?"
                          @confirm="() => del(record.id)">
                        <a>删除</a>
                      </a-popconfirm>
                   </a-menu-item>
                 </a-menu>
               </a-dropdown>
        </template>
      </a-table>
    </div>
    <!--  选择链接    -->
    <linkDialog
        :visible="visible"
        v-on:closeMain="closeMain"
        v-on:checkList="checkList"
    ></linkDialog>
  </div>
</template>

<script>
import {update} from "@/utils/update";
import {
  deleteLiveStreamGallery,
  getByFuncId,
  liveStreamGalleryUpOrDown,
  postInsertLiveStreamGallery,
  putStreamFunc
} from "@/service/liveManage";
import linkDialog from "@/components/Material/linkDialog";
const columns = [
  {
    title: '序号',
    align:'center',
    scopedSlots: { customRender: 'serialNumber' },
  },{
    title: '图片',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    align:'center',
    width:'180px',
    scopedSlots: { customRender: 'imageUrl' },
  },{
    title: '标题',
    dataIndex: 'name',
    key: 'name',
    align:'center',
    scopedSlots: { customRender: 'name' },
  },{
    title: '创建时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    align:'createdTime',
  },{
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align:'center',
    scopedSlots: { customRender: 'action' },
  },
];
const data = [];
export default {
  name: "ExhibitionHall",
  components:{
    linkDialog
  },
  data(){
    // this.cacheData = data.map(item => ({ ...item }));
    return{
      picList:[],
      visible:false,
      isShow:false,
      columns,
      data,
      editingKey: '',
      pageNo:1,
      pageSize:10,
      total:0,
      titleVal:'',
    }
  },
  computed:{
    funListId(){
      return this.$store.state.funId;
    },
    funList(){
      return this.$store.state.funList;
    },
  },
  created() {
    this.liveChatRecord();
  },
  methods:{
    closeMain(val) {
      this.visible = val;
    },
    //新增弹窗
    addTable(){
      this.visible = true;
    },
    checkList(val) {
      this.picList = val;
      for (let i = 0; i < this.picList.length; i++){
        let data = {
          "streamId":this.funList.streamId,
          "funcId":this.funList.id,
          "contentId":this.picList[i].id
        }
        this.insertLiveStreamGallery(data)
        this.$message.success('新增成功！');
      }
    },
    async insertLiveStreamGallery(data) {
      const response = await postInsertLiveStreamGallery(data);
      if (response.code === 200) {
        await this.liveChatRecord()
      } else {
        this.$message.warning(response.message);
      }
    },
    openLink(link){
      window.open(link)
    },
    async upOrDown(type,id) {
      const response = await liveStreamGalleryUpOrDown(type,id);
      if (response.code === 0) {
        this.$message.success('操作成功！');
        await this.liveChatRecord()
      } else {
        this.$message.warning(response.message);
      }
    },
    //获取云展厅列表
    async liveChatRecord() {
      const response = await getByFuncId(this.funListId,this.titleVal);
      if(response.code === 0){
        this.data = response.data;
        this.isShow = response.isShow ? true:false;
      }else {
        this.$message.warning(response.message);
      }
    },
    switchChange(isShow){
      this.isShow = isShow;
      let data = {
        "id": this.funList.id, //id
        "streamId": this.funList.streamId, //直播间id
        "functionCode": this.funList.functionCode, //功能code值
        "isShow":isShow ? 1:0  //0 关闭 1开启
      }
      this.putStreamFuncs(data)
    },
    // 修改直播间设置功能列表
    async putStreamFuncs(data) {
      const response = await putStreamFunc(data)
      if(response.code === 200){
        this.$message.success("修改成功！");
        await this.liveChatRecord();
      }else {
        this.$message.warning(response.message);
      }
    },
    confirm(){

    },
    onSearch(){
      this.liveChatRecord()
    },
    paginationChange(){

    },
    async upload(inputId,id,idImg){
      let inputDOM = document.getElementById(inputId).files[0]
      if(inputDOM.size < 2086666.24 ){
        const image = await update(inputDOM)
        if(image.code === 0){

          for (let i = 0;i<this.data.length;i++){
            if(this.data[i].id === id){
              this.data[i].imageUrl = image.data.url
            }
          }
          this.$message.success('上传成功')
          //  隐藏上传按钮
          // document.getElementById(idImg).style.opacity = 0;

        } else{
          this.$message.warning('上传失败'+image.message)
        }
      } else{
        this.$message.warning('仅上传2M内图片')
      }
    },

    async del(id) {
      const response = await deleteLiveStreamGallery(id);
      if (response.code === 200) {
        this.$message.success('删除成功！');
        await this.liveChatRecord()
      } else {
        this.$message.warning(response.message);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ExhibitionHall {
  width: 100%;
  //border-left: 1px solid #d9d9d9;
  .ExhibitionHall-title {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #F5F5F5;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .title {
      font-weight: bold;
      margin-left: 10px;
    }
  }
  .ExhibitionHall-content{
    width: 98%;
    margin: auto;
    padding-bottom: 40px;
  }
  .uploadImg{
    border: #DDDDDD 2px dashed;
    width: 120px;
    height: 80px;
    background-image: url("../../assets/add.png");
    background-size:100% 100%;
    background-repeat:no-repeat;
    cursor: pointer;
  }
  .upload_image{
    border: #DDDDDD 2px dashed;
    width: 120px;
    height: 80px;
    text-align: center;
  }
  .icon{
    color: red;
    position: absolute;
    left:76%;
    top: 8%;
    cursor: pointer;
  }
}
</style>
